@import "../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
    "info": tomato,
    "danger": teal
);

.navbar .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  padding: 0;
}